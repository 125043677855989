import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsAndConditions = () => {
  return (
    <Layout>
      <SEO title="Terms and Conditions" />
      <section>
        <h2>Terms of Use</h2>
        <p>
          These Terms of Use apply to the use of the LEAPSynSCI website located
          at http://leapsynsci.com/ operated by LEAPSynSCI, (refered further as
          "Website"). By accessing or using the Website, you expressly agree to
          the Terms of Use without any further declaration being required. If
          you do not agree to these Terms of Use, please do not use the website.
          LEAPSynSCI reserves the right to change, modify, add or remove
          portions of these Terms of Use at any time and without prior notice,
          upon its sole discretion. Please check these Terms of Use from time to
          time for any modifications. Your continued use of the website will
          mean that you have accepted and agreed to the changes.
          <h3>1. Content and Scope of Use </h3>
          <ul>
            <li>
              1.1. All content accessible on the website, including text,
              graphics, images, photographs, trademarks, logos, videos, user
              interfaces, visual interfaces applications, programs, computer
              code and other information (collectively, the "Content"),
              including but not limited to the design, layout, “look insight”
              and arrangement of such Content, is owned by LEAPSynSCI or its
              licensors and is protected by copyright, trademark and other
              intellectual property and unfair competition laws.
            </li>
            <li>
              1.2 You may solely for private, educational, personal, scientific,
              or research purposes access, browse, view, display, search,
              download and print the Content.
            </li>
            <li>
              1.3 You may not remove, obscure or alter any copyright or other
              notices, trademarks, logos, service marks or any other proprietary
              rights appearing in or on the Content, update, change, revise,
              adapt, modify, translate, transform or create any derivative work
              of the Content re-distribute, reproduce, or transmit the Content
              by any means including electronic (e. g., via e-mail) nor post it
              on their personal or public websites or on public networks,
              systematically download any Content, use routines designed to
              continuously and automatically search and index the Content (full
              text and meta data), such as web-crawling or spider programs or
              engage in any activity likely to burden the website, directly or
              indirectly use or assist any third party to use the Content for
              any commercial or monetary purposes including without limitation
              any sale, resale, loan, transfer or upload of the Content to a
              commercial entity’s internet website, use the website to publish,
              distribute or advertise any promotional material or other forms of
              solicitation to other users or any other services facilitation of
              any activities that are prohibited by law.
            </li>
          </ul>
          <h3>2. User accounts </h3>
          <ul>
            <li>
              2.1 You must ensure that the information you are asked to provide
              on registration is accurate. In particular, you must not enter
              details of others and are obliged to update your personal
              information without undue delay in the event of any change.
            </li>
            <li>
              2.2 Your access details (email address and password or user name
              and password) are intended only for use by you personally. You are
              not authorized to share these access details with others or
              otherwise disclose them. If you learn of any actual or suspected
              unauthorized use of your access details, you shall be under an
              obligation to notify LEAPSynSCI promptly. If you are responsible
              for such misuse of your access details, you shall be liable for
              all consequences of use by a third party. Your liability ends only
              if and when you have notified LEAPSynSCI of the unauthorized use
              or loss of your access details and changed your password, if
              necessary.
            </li>
            <li>
              2.3 LEAPSynSCI reserves the right to refuse registrations on a
              case-by-case basis without stating reasons. Moreover, LEAPSynSCI
              remains entitled at any time to disable access details temporarily
              or permanently in cases of violation of these terms and conditions
              of use (in particular if false information was provided on
              registration and/or access details, in particular passwords, were
              disclosed to others without authorization) and/or terminate your
              access with immediate effect or within a period to be determined
              at LEAPSynSCI’s discretion and/or to terminate the agreement on
              use by extraordinary termination without notice. The same shall
              apply in cases of misuse or damage or if the functionality of the
              offering is impaired. If this is the case, you shall not be
              entitled to re-register without LEAPSynSCI’s express prior
              consent. LEAPSynSCI expressly reserves the right to make claims
              for damages.
            </li>
          </ul>
          <h3>4. Representations, Warranties, Indemnification </h3>
          <ul>
            <li>
              4.1 The availability of the Websites at all times is not
              guaranteed. In particular, the Websites may be temporarily
              unavailable for maintenance or technical reasons without this
              giving rise to any claims against LEAPSynSCI. To the extent
              permitted by applicable law, LEAPSynSCI provides the content “as
              is” and makes no other representation or warranty. LEAPSynSCI
              expressly disclaims any liability for any claim arising from or
              out of the content, including but not limited to any errors,
              inaccuracies, omissions, or defects contained therein, and any
              implied or express warranty as to merchantability or fitness for a
              particular purpose.
            </li>
            <li>
              4.2 To the extent permitted under applicable law, no
              responsibility is assumed for any injury and/or damage to persons,
              animals or property as a matter of product liability, negligence
              or otherwise, or from any use or operation of any ideas,
              instructions, methods, products or procedures contained in the
              Websites.
            </li>
            <li>
              4.3 TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT
              SHALL LEAPSynSCI OR ITS SUPPLIERS OR LICENSORS BE LIABLE FOR ANY
              DAMAGES (INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, SPECIAL,
              INCIDENTAL, INDIRECT, OR SIMILAR DAMAGES, PERSONAL INJURY
              (INCLUDING DEATH), LOSS OF PROFITS, CORRUPTION OR LOSS OF DATA,
              BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES)
              ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THE
              WEBSITE OR ITS CONTENT, PRODUCTS OR SERVCES.
            </li>
            <li>
              4.4 LEAPSynSCI does not claim ownership, endorse or take
              responsibility for any third-party products, information,
              guidelines, materials or services that may be offered, advertised,
              provided or displayed on the Websites or incorporated in the
              Content, products or services contained on, accessible from or
              distributed through the Websites.
            </li>
          </ul>
        </p>
      </section>
    </Layout>
  )
}

export default TermsAndConditions
